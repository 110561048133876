
import { Component, Vue } from 'vue-property-decorator'
import { ElForm } from 'element-ui/types/form'
import { wareHousingList, AssetList, TypesTree, AreaTree } from '@/types/assetScheduling'
import { ProjectUser } from '@/types/project'
import { pInteger } from '@/utils/validate'
import UploadFile from '@/components/uploadFile/Index.vue'

interface UserInfo {
  userId: string;
  initPwd: boolean;
  userName: string;
}
@Component({
  name: 'warehousingAdd',
  components: { UploadFile }
})
export default class ScheduleAdd extends Vue {
  private disabled = true
  private info: any = {
    orderDate: '',
    projectId: '',
    remarks: '',
    assetList: []
  }

  private props = {
    children: 'childNode',
    label: 'assetTypeName',
    value: 'assetType'
  }

  private props1 = {
    children: 'childNode',
    label: 'assetLocationName',
    value: 'assetLocation'
  }

  private operation = false
  private list: TypesTree[] = []
  private areaList: AreaTree[] = []
  private projectUserList: ProjectUser[] = []
  private equityList = []

  private nowIndex = null as any // 当前操作索引

  pickerOptions = {
    disabledDate: (time: Date) => {
      // 大于当前日期禁止选择
      return time.getTime() > Date.now()
    }
  }

  private validtor999 = (rule: any, value: string, callback: Function) => {
    if (+value > 999) {
      return callback(new Error('请输入1-999的数字'))
    }
    return callback()
  }

  private validtor99999 = (rule: any, value: string, callback: Function) => {
    const reg = /^[0-9]+(.[0-9]+)?$/
    if (value) {
      if (!reg.test(value) || Number(value) > 999999999.99) {
        return callback(new Error('请输入0到999999999.99的数'))
      }
    }
    return callback()
  }

  private rules = {
    projectId: [
      { required: true, message: '请选择项目名称', trigger: ['blur', 'change'] }
    ],
    orderDate: [
      { required: true, message: '请输入入库日期', trigger: ['blur', 'change'] }
    ],
    assetName: [
      { required: true, message: '请输入资产名称', trigger: ['blur', 'change'] }
    ],
    assetType: [
      { required: true, message: '请选择资产分类', trigger: ['blur', 'change'] }
    ],
    brand: [
      { required: true, message: '请输入品牌', trigger: ['blur', 'change'] }
    ],
    custodianId: [
      { required: true, message: '请选择保管员', trigger: ['blur', 'change'] }
    ],
    locationId: [
      { required: true, message: '请选择存放位置', trigger: ['blur', 'change'] }
    ],
    model: [
      { required: true, message: '请输入型号', trigger: ['blur', 'change'] }
    ],
    purchaseTime: [
      { required: true, message: '请选择购置时间', trigger: ['blur', 'change'] }
    ],
    purchaseAmount: [
      { required: true, message: '请选择购置金额（含税）', trigger: ['blur', 'change'] },
      { validator: this.validtor99999, trigger: ['blur', 'change'] }
    ],
    expectedServiceLife: [
      { validator: pInteger, trigger: ['blur', 'change'] },
      { validator: this.validtor999, trigger: ['blur', 'change'] }
    ],
    assetRight: [
      { required: true, message: '请选择产权所属', trigger: ['blur', 'change'] }
    ]
  }

  get projectList () {
    return this.$store.state.projectList
  }

  get userInfo (): UserInfo {
    const userInfo = JSON.parse(sessionStorage.getItem('userInfo') as string) as UserInfo
    return userInfo || { userId: '', isInitPwd: false }
  }

  created () {
    this.getList()
    this.getAreaList()
    this.getEquityList()
  }

  // 文件上传成功返回
  onSuccess (file: any) {
    console.log('file', file);
    (this.info as any).assetList[this.nowIndex].fileList.push(file)
  }

  imgRemove () {
    (this.info as any).assetList[this.nowIndex].fileList.splice(0, 1)
  }

  // 查询二级类型
  getList () {
    this.$axios.get(this.$apis.assetScheduling.selectAssetTypeByList, {
      level: 2
    }).then(res => {
      this.list = res || []
    })
  }

  // 位置查询二级类型
  getAreaList () {
    this.$axios.get(this.$apis.assetScheduling.selectAssetLocationByList, {
      level: 2
    }).then(res => {
      this.areaList = res || []
    })
  }

  // 获取项目下所有人信息
  getProjectUserList () {
    this.info.assetList = []
    this.operation = false
    this.$axios.get(this.$apis.project.selectProjectUserByList, {
      projectId: this.info.projectId
    }).then((res) => {
      this.projectUserList = res.list || []
    })
  }

  // 查询资产信息
  getEquityList () {
    this.$axios.get(this.$apis.assetScheduling.selectAssetRightByList).then((res) => {
      this.equityList = res.list || []
    })
  }

  // 表头颜色样式
  rowClass ({ row, column, rowIndex, columnIndex }: any) {
    const index = [1, 2, 3, 4, 6, 7, 8, 9, 10]
    if (rowIndex === 0 && (index.includes(columnIndex))) {
      return 'color:red'
    }
  }

  addAssets () {
    if (!this.info.projectId) {
      this.$message.warning('请先选择项目')
      return false
    }
    if (this.operation) {
      this.$message.warning('请先保存数据')
    } else {
      this.operation = true
      this.info.assetList.push({
        assetType: '',
        assetName: '',
        brand: '',
        // 型号
        model: '',
        // 设备序列号
        equipmentSerialNumber: '',
        // 存放位置
        locationId: '',
        // 保管员
        custodianId: '',
        // 购置时间
        purchaseTime: '',
        // 购置金额（含税）
        purchaseAmount: '',
        // 预计使用期限（月）
        expectedServiceLife: '',
        // 备注
        remarks: '',
        isEdit: true,
        assetTypeName: '',
        locationName: '',
        custodianName: '',
        assetRight: '',
        assetRightName: '',
        fileList: []
      })
      this.nowIndex = this.info.assetList.length - 1
      this.$nextTick(() => {
        ;(this.$refs.table2 as any).bodyWrapper.scrollTop = (this.$refs.table2 as any).bodyWrapper.scrollHeight
      }) // 滚动到表格最底部
    }
  }

  onSave (row: any, index: any) {
    ;(this.$refs.table as ElForm).validate(valid => {
      if (valid) {
        const checkList = (this.$refs.assetType as HTMLFormElement).getCheckedNodes(true)
        const checkLocation = (this.$refs.location as HTMLFormElement).getCheckedNodes(true)
        row.assetTypeName = checkList[0].pathLabels[0] + (checkList[0].pathLabels[1] ? '/' + checkList[0].pathLabels[1] : '')
        row.locationName = checkLocation[0].pathLabels[0] + (checkLocation[0].pathLabels[1] ? '/' + checkLocation[0].pathLabels[1] : '')
        const personInfo = this.projectUserList.filter(item => {
          return item.userId === row.custodianId
        })
        row.custodianName = (personInfo[0] && personInfo[0].name) || ''
        const equityInfo: any = this.equityList.filter((item: any) => {
          return item.assetRight === row.assetRight
        })
        row.assetRightName = (equityInfo[0] && equityInfo[0].assetRightName) || ''
        this.operation = false
        // delete this.info.assetList[index].isEdit
        this.$delete(row, 'isEdit')
        // this.$set(this.info.assetList, index, row)
        // this.info.assetList.splice(index, 1, row)
        // row.isEdit = false
        this.info.assetList.splice(index, 1, row)
        this.nowIndex = null
      }
    })
  }

  onEdit (row: any, index: any) {
    if (this.operation) {
      this.$message.warning('请先保存数据')
    } else {
      this.operation = true
      // this.info.assetList[index].isEdit = true
      // this.$set(this.info.assetList, index, row)
      this.$delete(row, 'isEdit')
      this.info.assetList.splice(index, 1, { ...row, isEdit: true })
      this.nowIndex = index
    }
  }

  onDelete (index: number) {
    this.info.assetList.splice(index, 1)
    this.operation = false
    if (index === this.nowIndex) {
      this.nowIndex = null
    } else if (index < this.nowIndex) {
      this.nowIndex = this.nowIndex - 1
    }
  }

  submit (callback: Function) {
    ;(this.$refs.info as ElForm).validate(valid => {
      if (valid) {
        if (this.operation) {
          this.$message.warning('请先保存')
          return false
        }
        this.$axios.post(this.$apis.assetScheduling.insertAssetPutLib, {
          ...this.info
        }).then(() => {
          this.$message.success('保存成功')
          callback()
        })
      }
    })
  }

  onSubmit () {
    this.submit(() => {
      this.$router.back()
    })
  }
}
